console.log('main_android is running')

function getOS() {
  let platform = "other" // android, ios, other
  console.log('userAgent', navigator.userAgent);
  if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    platform = 'ios'
  }
  if (/android/i.test(navigator.userAgent)) {
    platform = "android";
  }

  return platform;
}

// const htmlElement = document.documentElement
// htmlElement.style.fontSize = '14px';

window.addEventListener('load', function(){
  let event = 'clientLoad-other'
  if(getOS() == 'ios'){
    event = 'clientLoad-ios'
  }
  if(getOS() == 'android'){
    event = 'clientLoad-android'
  }
  myEvent('event', event, {
    'page_location': window.location,
    'client_id': window.clientID,
    'user_agent': navigator.userAgent,
  });
})

window.addEventListener("scroll", () => {
  const header = document.getElementById("header");
  if (window.scrollY > 0) {
    header?.classList.add("header-active");
  } else {
    header?.classList.remove("header-active");
  }
});

/************************************************/
if ("serviceWorker" in navigator) {
  console.log("registering serviceWorker window.location.href", window.location.href);
  // console.log("registering serviceWorker", window.location.origin, window.location.pathname);
  let path = '';
  if (window.location.pathname.endsWith("/")) {
    path = "service-worker.js";
  } else {
    path = "/service-worker.js";
  }
  const pathname = window.location.pathname.replace(/^(\/[^/]+)?\/store/, '/store');
  const swpath = window.location.origin + pathname + path
  console.log("compute sw", swpath);
  navigator.serviceWorker.register(swpath);
}